import React, { Suspense, useLayoutEffect } from "react";
import {
  HashRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
// Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));

const SacramentDetails = React.lazy(() =>
  import("./components/pages/SacramentDetails")
);
// Home
const Home = React.lazy(() => import("./components/pages/Home"));

const Header = React.lazy(() => import("./components/layouts/Header"));
const Footer = React.lazy(() => import("./components/layouts/Footer"));
const Sacraments = React.lazy(() => import("./components/pages/Sacraments"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
const Groups = React.lazy(() => import("./components/pages/Groups"));
const GroupDetails = React.lazy(() =>
  import("./components/pages/GroupDetails")
);
const Saints = React.lazy(() => import("./components/pages/Saints"));
const SaintDetails = React.lazy(() =>
  import("./components/pages/SaintDetails")
);
const Events = React.lazy(() => import("./components/pages/Events"));
const Gallery = React.lazy(() => import("./components/pages/Gallery"));
const Leaders = React.lazy(() => import("./components/pages/Leaders"));
const LeaderDetails = React.lazy(() =>
  import("./components/pages/LeaderDetails")
);

// Sermons
const Sermons = React.lazy(() => import("./components/pages/Sermons"));
const Sermondetails = React.lazy(() =>
  import("./components/pages/Sermondetails")
);
const ChurchProjects = React.lazy(() =>
  import("./components/pages/ChurchProjects")
);
// Error 404
const Errorpage = React.lazy(() => import("./components/pages/404"));
const About = React.lazy(() => import("./components/pages/About"));
// Scroll to Top
const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
});

function App() {
  return (
    <Router>
      <Suspense
        fallback={
          <div>
            {" "}
            <Preloader />
          </div>
        }
      >
        <ScrollToTop>
          <Preloader />
          <div>
            <Header />
          </div>
          <Switch>
            {/* Home */}
            <Route exact path="/" component={Home} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/sacraments" component={Sacraments} />
            <Route exact path="/sacraments/:id" component={SacramentDetails} />
            <Route exact path="/groups" component={Groups} />
            <Route exact path="/groups/:id" component={GroupDetails} />
            <Route exact path="/saints" component={Saints} />
            <Route exact path="/saints/:id" component={SaintDetails} />
            <Route exact path="/sermons" component={Sermons} />
            <Route
              exact
              path="/sermon/:id"
              component={(props) => (
                <Sermondetails {...props} key={window.location.pathname} />
              )}
            />
            <Route exact path="/events" component={Events} />
            <Route exact path="/gallery" component={Gallery} />
            <Route exact path="/churchprojects" component={ChurchProjects} />
            <Route exact path="/leaders" component={Leaders} />
            <Route exact path="/about" component={About} />
            <Route exact path="/leaders/:id" component={LeaderDetails} />
            {/* Error 404 */}
            <Route exact path="/404" component={Errorpage} />
            <Route exact component={Errorpage} />
          </Switch>
          <footer
            className="sigma_footer footer-2 bg-cover bg-center bg-transparent light-overlay"
            style={{
              backgroundImage:
                "url(" + process.env.PUBLIC_URL + "/assets/img/footer.jpg)",
            }}
          >
            <Footer />
          </footer>
        </ScrollToTop>
      </Suspense>
    </Router>
  );
}

export default App;
